import { axiosInstance } from "../store";
import { handlStatusCodeMessage, jsonToFormData, jsonToFormData_v2 } from "../helpers";
import IProduct from "../dtos/IProduct";
import IVendor from "../dtos/IVendor";
import { ILastUpdated } from "../dtos/IFDCLastUpdated";

export function fetchVendors(page = 0, size = 20, sort = "", name = ''): Promise<{ vendors: IVendor[], totalVendorsCount: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/catalog/vendor?page=${page}&size=${size}&sort=${sort}&name=${name}`)
        .then((res) => {
            return res.status == 200 ? { vendors: res.data, totalVendorsCount: parseInt(res.headers["x-total-count"]) } : { vendors: [], totalVendorsCount: 0 }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'vendor' });
            return { vendors: [], totalVendorsCount: 0 };
        })
}
export function fetchVendor(vendorId: string): Promise<{ vendor: IVendor }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/catalog/vendor/${vendorId}`)
        .then((res) => {
            return res.status == 200 ? res.data : null;
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'vendor' });
            return null;
        })
}
export function verifyVendor(email: string): Promise<{ vendor: IVendor }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/catalog/vendor/vendor-email?email=${email}`)
        .then((res) => {
            return res.status == 200 ? res.data : null;
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'vendor' });
            return null;
        })
}
export function exportVendorMetadata(vendorId: number, token: string): Promise<any> {
    return fetch(`${process.env.REACT_APP_API_URL}/catalog/export/${vendorId}/product-vendor-metadata?storefront_id=1`, {
        headers: {
            "Accept": "*/*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then((res: any) => {
            handlStatusCodeMessage({ code: res.status, entity: 'vendor' });
            return res.status == 200 ? res.text() : '';
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'vendor' });
            return '';
        })
}
export function exportVendorStock(vendorId: number, token: string): Promise<any> {
    return fetch(`${process.env.REACT_APP_API_URL}/catalog/export/${vendorId}/product-vendor-stockdata?storefront_id=1`, {
        headers: {
            "Accept": "*/*",
            "Authorization": `Bearer ${token}`
        }
    }).then((res: any) => {
        handlStatusCodeMessage({ code: res.status, entity: 'vendor' });
        return res.status == 200 ? res.text() : '';
    })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'vendor' });
            return '';
        })
}
export function fetchVendorProducts(page = 0, size = 20, sort = "", vendorId = "", productStatus = "", vendorStatus = "", status = "", qty = 10, fdcQty = 10, name = "", productId = "",
    categories = "",
    variants = "",
    priceFrom = "",
    priceTo = "",
): Promise<{ vendorsProducts: any[], totalVendorProducts: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/catalog/product-vendor?page=${page}&size=${size}&sort=${sort}&vendor_id=${vendorId}&name=${name}&sku=${name}&vendor_product_sku=${name}&product_id=${productId}&product_status=${productStatus}&vendor_status=${vendorStatus}&status=${status}&${categories ? `categories=${categories}` : ""}&${variants ? `variants=${variants}` : ""}&price_from=${priceFrom}&price_to=${priceTo}&exclude_status=O&vendor_portal=${process.env.REACT_APP_IS_VENDOR}${qty == 0 ? `&quantity=${qty}&fdc_quantity=${fdcQty}` : ''}&search_engine_type=elastic`)
        .then((res) => {
            return res.status == 200 ? { vendorsProducts: res.data, totalVendorProducts: parseInt(res.headers["x-total-count"]) } : { vendorsProducts: [], totalVendorProducts: 0 }
        }).catch((err) => {

            handlStatusCodeMessage({ code: err.response?.status, entity: 'vendor' });
            return { vendorsProducts: [], totalVendorProducts: 0 };
        })
}
export function getVendorLastUpdate(page = 0, size = 20, vendorId = 0, sort = "", type = ""): Promise<{ history: any[], totalHistoryCount: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/catalog/upload-task?page=${page}&size=${size}&vendor_id=${vendorId}&sort=${sort}&type=${type}`)
        .then((res) => {
            return res.status == 200 ? { history: res.data, totalHistoryCount: parseInt(res.headers["x-total-count"]) } : { history: [], totalHistoryCount: 0 }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'vendor' });
            return { history: [], totalHistoryCount: 0 };
        })
}

export function createVendor(vendor: any): Promise<any> {
    let formData = jsonToFormData_v2(vendor);

    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/catalog/vendor`, formData, {
        headers: {
            "Accept-Language": "",
            "Content-Type": "multipart/form-data",
            "Accept": "*/*",
        }
    })
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: "vendor" });
                return res.data
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'vendor' });
                return null;
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'vendor' });
            return null;
        })
}

export function updateVendor(vendor: any, vendorID?: number): Promise<any> {
    let formData = jsonToFormData_v2(vendor);
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/catalog/vendor${vendorID && `/${vendorID}`}`, formData, {
        headers: {
            "Accept-Language": "",
            "Content-Type": "multipart/form-data",
            "Accept": "*/*",
        }
    })
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: "vendor" });
                return res.data
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'vendor' });
                return null;
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'vendor' });
            return null;
        })
}

export function updateVendorStatus(vendor: any): Promise<any> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/catalog/vendor/${vendor.id}/change-status?status=${vendor.status}`)
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: "vendor" });
                return res.data
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'vendor' });
                return null;
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'vendor' });
            return null;
        })
}

export function updateVendorProfile(vendor: any, vendorId: string, storefrontId: string): Promise<any> {
    let formData = jsonToFormData(vendor);
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/catalog/vendor/vendor-portal?storefront_id=${storefrontId}&tenant_id=${vendorId}`, formData, {
        headers: {
            "Accept-Language": "",
            "Content-Type": "multipart/form-data",
            "Accept": "*/*",
        }
    })
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: "vendor" });
                return res.data
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'vendor' });
                return null;
            }
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response?.status, entity: 'vendor' });
            return null;
        })
}