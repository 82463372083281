export const colors = {
  blue: {
    deep: "rgb(0, 121, 191)",
    light: "lightblue",
    lighter: "#d9fcff",
    soft: "#E6FCFF",
    status: "#00C4C4",
    statusDark: "#000EB7",
  },
  black: "#4d4d4d",
  shadow: "rgba(0,0,0,0.2)",
  grey: {
    darker: "#C1C7D0",
    dark: "#E2E4E6",
    medium: "#DFE1E5",
    N30: "#EBECF0",
    light: "#F4F5F7",
    darkTitle: "#666666",
    darkText: "#979797",
    lightTitle: "#B2B2B2",
    statusGrey: "#727272",
    modalGreyTilte: "#707070",
    backgroundColor: "#F1F3F6",
    slaTextColor: "#F9F9F9",
  },
  green: "rgb(185, 244, 188)",
  greenShades: {
    status: "#209024",
  },
  redShades: {
    status: "#FF4C2E",
    failedStatus: "#970000",
  },
  orangeYellow: "#F1B100",
  white: "#FFFFFF",
  purple: "rebeccapurple",
  purpleShades: {
    status: "#3B005E",
    cancellationInProgress: "#5F3D87",
  },
  orange: "#FF991F",
  xmartRed: "#DB5867",

  allZones: "#818182",
  hotZone: "#EC7373",
  warmZone: "#FCB831",
  coldZone: "#50CE89",
  cardText: "#464E5F",

  pendingColor: "#005A80",
  disabledColor: "#EC5453",
  successColor: "#28C66F"
};
export const grid = 8;

export const borderRadius = 2;
const rowStyle = {
  width: "100%",
  display: "flex",
  flexFlow: "row wrap",
};
const colStyle = {
  marginBottom: "16px",
};
const gutter = 16;

const basicStyle = {
  rowStyle,
  colStyle,
  gutter,
};

export const stateColorCode = {
  incomplete: "#000000",
  open: "#F98E00",
  rfp: "#0099FF",
  packed: "#758350",
  shipping: "#1C4D99",
  complete: "#209024",
  canceled: "#FF00A1",
  returned: "#9F00FF",
  failed: "#F1CF00",
  cip: "#9572AA",
  draft: "#415CFF",
  needApproval: "#920000"
}

export default basicStyle;
