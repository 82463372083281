import { axiosInstance } from "../store";
import IUser from "../dtos/IUser";
import IBranch from "../dtos/IBranch";
import { handlStatusCodeMessage } from "../helpers";
import { toast } from "react-toastify";
import IOrderAddress from "../dtos/IOrderAddress";
import IAddress from "../dtos/IAddress";

export function validateUser(
    user: any
): Promise<{
    userValidated: boolean;
    service_account_id: number | null;
    my_branches: IBranch[] | null;
    user_id: number | null;
    storefronts: any;
}> {
    return axiosInstance
        .get(
            `${process.env.REACT_APP_API_URL}/account/user/sign-in?email=${user.idTokenParsed.email}&subject_id=${user.subject}&component_id=${process.env.REACT_APP_COMPONENT_ID}`
        )
        .then((res) => {
            if (res.status == 200) {
                return {
                    userValidated: true,
                    service_account_id: res.data.user_info.service_account_id,
                    my_branches: res.data.user_info.branches,
                    user_permissions: res.data.user_permissions,
                    user_id: res.data.user_info.user_id,
                    user_profile: res.data.user_profile,
                    storefronts: res.data.storefronts,
                };
            } else {
                return {
                    userValidated: false,
                    service_account_id: null,
                    my_branches: [],
                    user_permissions: [],
                    user_id: null,
                    storefronts: [],
                };
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user', text: err.response.data.message });
            return {
                userValidated: false,
                service_account_id: null,
                my_branches: [],
                user_permissions: [],
                user_id: null,
                storefronts: []
            };
        });
}

export function sendInvitation(invitation: any): Promise<boolean> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_API_URL}/account/invitation/send?component_id=2`,
            invitation
        )
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return true
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return false
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function validateInvitation(token: string): Promise<boolean> {
    return axiosInstance
        .get(`${process.env.REACT_APP_API_URL}/account/invitation/validate/${token}?component_id=2`)
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function fetchUser(subject_id: IUser["user_id"], component_id = 2): Promise<any> {
    return axiosInstance
        .get(`${process.env.REACT_APP_API_URL}/account/user/${subject_id}?component_id=${component_id}`)
        .then((res) => (res.status == 200 ? res.data : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function deleteUser(subject_id: IUser["user_id"]): Promise<any> {
    return axiosInstance
        .delete(`${process.env.REACT_APP_API_URL}/account/user/${subject_id}?component_id=2`)
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function deactivateUserCard(
    id: IUser["user_id"],
    cardId: number
): Promise<any> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_API_URL}/account/access_card/deactivate_card/?user_id=${id}&access_card_id=${cardId}`
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function deactivateAuthUserCard(
    id: IUser["user_id"],
    cardId: number
): Promise<any> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_API_URL}/account/access_card/deactivate_my_card/?user_id=${id}&access_card_id=${cardId}`
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function requestUserCard(id: IUser["user_id"]): Promise<any> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_API_URL}/account/access_card/user?user_id=${id}`
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;

        });
}
export function resendInvitation(id: IUser["user_id"]): Promise<any> {
    return axiosInstance
        .post(
            `${process.env.REACT_APP_API_URL}/account/invitation/resend?component_id=2&user_id=${id}`
        )
        .then((res) => (res.status == 200 ? true : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}

export function updateUser(
    id: IUser["user_id"],
    userGroupIds: number[],
): Promise<any> {
    return axiosInstance
        .put(`${process.env.REACT_APP_API_URL}/account/user/${id}?component_id=2`, {
            user_group_ids_list: userGroupIds,
        })
        .then((res) => (res.status == 200 ? toast.success('changes saved successfully.') : false))
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return false;
        });
}
export function fetchUsers(
    componentId = 4,
    page = 0,
    size = 10,
    sort = "",
    name = "",
    email = "",
    firstName = "",
    lastName = ""
): Promise<{ users: IUser[], totalUsersCount: number }> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/user/get-user?component_id=${componentId}&page=${page}&size=${size}&email=${email}&first_name=${firstName}&last_name=${lastName}&sort=${sort}${name ? `&name=${name}` : ""}`)
        .then((res) => res.status == 200 ? {
            users: res.data,
            totalUsersCount: parseInt(res.headers["x-total-count"]),
        } : {
            users: [],
            totalUsersCount: 0,
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return {
                users: [],
                totalUsersCount: 0,
            };
        });
}
export function fetchSales(): Promise<any> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/sales-staff?component_id=4`)
        .then((res) => {
            return res.data;
        }).catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return [];
        })
}
export function assignSales(userId: number, salesName: string) {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/account/user/assign/sales`, {
        user_id: userId,
        sales_name: salesName
    }).then(res => res.data).catch((err) => {
        handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
        return undefined;
    })
}

export function searchUser(firstName = "", lastName = "", mobileNumber = "", email = ""): Promise<IUser[]> {
    let containsSpace = /\s/g.test(firstName);
    if (containsSpace) {
        let splitted = firstName.split(" ");
        firstName = splitted[0];
        lastName = splitted[1];
    }
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/user/get-user?component_id=4&page=0&size=1000&first_name=${firstName}&last_name=${lastName}&mobile=${mobileNumber}&email=${email}`)
        .then((res) => {
            if (res.status == 200) {
                return res.data
            }
            else {
                return []
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return []
        });
}
export function fetchUserAddress(subjectId = ""): Promise<IOrderAddress | null> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/ecommerce-profile/${subjectId}/addresses?component_id=4`)
        .then((res) => {

            if (res.status == 200) {
                let defaultAddress = res.data.find((address: IOrderAddress) => address.is_default);
                if (defaultAddress) { return defaultAddress }
                else {
                    return res.data[0];
                }
            }
            else {
                return null;
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return null;
        });
}

export function createUserAddress(subject_id: string, address: IAddress): Promise<IAddress | null> {
    return axiosInstance.post(`${process.env.REACT_APP_API_URL}/account/ecommerce-profile/${subject_id}/add-address?component_id=4`, address)
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return res.data;
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return null;
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return null;
        });
}
export function updateUserAddress(subject_id: string, address: IOrderAddress): Promise<IAddress | null> {
    return axiosInstance.put(`${process.env.REACT_APP_API_URL}/account/ecommerce-profile/address/update/${address.id}/user/${subject_id}?component_id=4`, address)
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return res.data;
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return null;
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return null;
        });
}

export function deleteUserAddress(subject_id: string, address: IOrderAddress): Promise<IAddress | null> {
    return axiosInstance.delete(`${process.env.REACT_APP_API_URL}/account/ecommerce-profile/address/delete/${address.id}/user/${subject_id}?component_id=4`)
        .then((res) => {
            if (res.status == 200) {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return res.data;
            }
            else {
                handlStatusCodeMessage({ code: res.status, entity: 'user' });
                return null;
            }
        })
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return null;
        });
}


export function fetchUserAddresses(subject_id = ""): Promise<IOrderAddress> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/ecommerce-profile/${subject_id}/addresses?component_id=4`)
        .then((res) => res.status == 200 ? res.data : [])
        .catch((err) => {
            handlStatusCodeMessage({ code: err.response.status, entity: 'user' });
            return [];
        });
}

export function fetchQuickProfile(user_id: string): Promise<any> {
    return new Promise((resolve, reject) => {
        axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/ecommerce-profile/${user_id}/quick-profile`)
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                reject([]);
            })
    })
}

export function getUser(subject_id: "") {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/account/v2/user/id/${subject_id}`).then(res => { return res.data })
        .catch(err => { return { data: err.response.data, code: err.response.code } });
}