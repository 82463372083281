export const LOAD_PARENT_CATEGORIES = "LOAD_PARENT_CATEGORIES";
export const LOAD_PARENT_CATEGORIES_SAGA = "LOAD_PARENT_CATEGORIES_SAGA"

export const LOAD_CATEGORIES = "LOAD_CATEGORIES";
export const LOAD_CATEGORIES_SAGA = "LOAD_CATEGORIES_SAGA"

export const UPDATE_IS_LOADING_CATEGORIES = "UPDATE_IS_LOADING_CATEGORIES"

export interface ILoadParentCategoriesActionSaga {
    readonly type: typeof LOAD_PARENT_CATEGORIES_SAGA;
}
export const loadParentCategoriesPropsSaga = (): ILoadParentCategoriesActionSaga => {
    return {
        type: LOAD_PARENT_CATEGORIES_SAGA,
    }
};


export interface ILoadCategoriesActionSaga {
    readonly type: typeof LOAD_CATEGORIES_SAGA;
    parentCategoryId: string
}
export const loadCategoriesPropsSaga = (parentCategoryId = ''): ILoadCategoriesActionSaga => {
    return {
        type: LOAD_CATEGORIES_SAGA,
        parentCategoryId: parentCategoryId,
    }
};
