import React,{ lazy,Suspense } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { useSelector } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE,PRIVATE_ROUTE } from "./route.constants";
import Loader from "@iso/components/utility/loader";
import { Provider } from "react-intl/src/components/injectIntl";
import { store } from "./store";
import { history } from "./store";

const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    redirect: true,
    component: lazy(() => import("@iso/containers/Dashboard/Dashboard")),
  },
  {
    path: PRIVATE_ROUTE.DASHBOARD,
    component: lazy(() => import("@iso/containers/Dashboard/Dashboard")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import("@iso/containers/Pages/404/404")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import("@iso/containers/Pages/500/500")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import("@iso/containers/Pages/SignUp/SignUp")),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ForgotPassword/ForgotPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ResetPassword/ResetPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import("@iso/containers/Authentication/Auth0/Auth0Callback")
    ),
  },
];
function PrivateRoute({ children,...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);

  return (
    <Route
      {...rest}
      render={
        ({ location }) =>
          // isLoggedIn ? (
          children
        // ) : (
        //   <Redirect
        //     to={{
        //       pathname: '/signin',
        //       state: { from: location },
        //     }}
        //   />
        // )
      }
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        {/* <Provider store={store}> */}
        <ConnectedRouter history={history}>
          <Switch>
            {publicRoutes.map((route,index) =>
              route.redirect ? (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={({ location }) => (
                    <Redirect
                      to={{
                        pathname: process.env.REACT_APP_IS_VENDOR == 'true' ? "/dashboard/myProducts" : "/dashboard/orders",
                        state: { from: location },
                      }}
                    />
                  )}
                />
              ) : (
                <Route key={index} path={route.path} exact={route.exact}>
                  <route.component />
                </Route>
              )
            )}
            {/* <Route path="/dashboard">
                <Dashboard />
              </Route> */}
          </Switch>
        </ConnectedRouter>
        {/* </Provider> */}
      </Suspense>
    </ErrorBoundary>
  );
}
