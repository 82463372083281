import _, { Primitive } from "lodash";
import { toast, ToastOptions } from "react-toastify";
import notification from '../components/Notification';
import { primitiveType } from "../dtos/global";
import AppLocale from '../config/translation';


declare global {
  interface Window {
    eventWaiters: any;
  }
}
const currentAppLocale = AppLocale["en"];

window.eventWaiters = {};


interface CustomMessage {
  text: string,
  type: 'error' | 'success' | 'warning' | 'info'
}

interface StatusCode {
  code: number,
  entity: string,
  text?: string
}
export const handlStatusCodeMessage = (statusCode: StatusCode, toastOptions?: ToastOptions): void => {
  let message: CustomMessage = { text: '', type: 'info' };
  switch (statusCode.code) {
    case 200 || 204:
      if (statusCode.entity === "cerate_order") {
        message = { text: `Order created successfully`, type: 'success' };
      } else if (statusCode.entity === "create_product") {
        message = { text: `Product created successfully`, type: 'success' }
      } else {
        message = { text: `operation succeeded`, type: 'success' }
      }
      break;
    case 202:
      message = { text: `operation succeeded`, type: 'success' }
      break;
    case 201:
      message = { text: `${statusCode.entity} has been created successfully`, type: 'success' }
      break;
    case 400:
      if (statusCode.entity === "vendor") {
        message = { text: `No profile found`, type: 'error' }
        break;
      }
      else {
        message = { text: `${statusCode.text}`, type: 'warning' }
        break;
      }
    case 401:
      message = { text: `you are unauthorized`, type: 'error' }
      break;
    case 403:
      message = { text: `you are not allowed to perform this action`, type: 'warning' }
      break;
    case 404:
      if (statusCode.entity === "po.vendor") {
        message = { text: `${currentAppLocale.messages["po.emptyMessage"]}`, type: 'info' };
        break;
      }
      message = { text: `No profile found`, type: 'error' }
      break;
    case 500:
      message = { text: `server exception`, type: 'error' }
      break;
    case 502:
      message = { text: `bad gateway`, type: 'error' }
      break;
    case 503:
      message = { text: `service temporary unavailable`, type: 'error' }
      break;
    case 409:
      message = { text: `User Already Exists`, type: 'error' }
      break;
    default:
      message = { text: 'Something went wrong', type: 'error' }
  }
  // overwrite default code message text
  message = { ...message, text: statusCode.text ? statusCode.text : message.text };
  // call taost
  // (toast as any)[message.type](message.text, toastOptions);
  notification(message.type, message.text);
}
export const addQueryString = (locationSearch: string, name: string, value: any): string => {
  const currentQueryStrings = locationSearch.replace('?', '').split('&')
    .filter((val, i) => (!val.includes(name)) && (val != ''))
    .join('&');
  return currentQueryStrings == '' ? `${name}=${value}` : `${currentQueryStrings}&${name}=${value}`;
}
export function getSureValue(value: any, type: primitiveType) {
  return typeof value == type ? value : undefined;
}
export function logSecretly(key: string = "", value: any) {
  if (process.env.REACT_APP_ENVIRONMENT == "development") {
    key ? console.log(key, value) : console.log(key, value)
  }
}
export function jsonToFormData(obj: any, form: FormData | undefined = undefined, nestedKey = ''): FormData {
  const formData = new FormData();
  Object.keys(obj)?.map((property: string, index: number) => {
    if (typeof obj[property] == "object" && obj[property]?.type) {
      if (obj[property]) {
        formData.append(`${property}`, obj[property])
      };
    }
    else if (typeof obj[property] == 'object' && obj[property] != null) {
      jsonToFormData(obj[property], formData, property);
    }
    else if (form && obj[property] != null) {
      form.append(`${nestedKey ? `${nestedKey}.` : ""}${property}`, obj[property]);

    }
    else if (obj[property] != null) {
      formData.append(property, obj[property]);
    }
  })
  return formData;
}

export function jsonToFormData_v2(obj: any, form: FormData | undefined = undefined, nestedKey = ''): FormData {
  const formData = new FormData();
  Object.keys(obj)?.map((property: string, index: number) => {
    if (typeof obj[property] == "object" && obj[property]?.type) {
      if (obj[property]) {
        formData.append(`${property}`, obj[property])
      };
    }
    else if (typeof obj[property] == 'object' && obj[property] != null) {
      jsonToFormData(obj[property], formData, property);
    }
    else if (form && obj[property] != null) {
      form.append(`${nestedKey ? `${nestedKey}.` : ""}${property}`, obj[property]);

    }
    else if (obj[property] != null) {
      formData.append(property, obj[property]);
    }
    if (Array.isArray(obj[property])) {
      obj[property].forEach((item, index) => {
        Object.keys(item).forEach((subKey) => {
          formData.append(`${property}[${index}].${subKey}`, item[subKey]);
        });
      });
    }
  })
  return formData;
}

export const currencyCode = () => {
  switch (localStorage.getItem("storefront")) {
    case "2":
      return "SAR";
    case "9":
      return "LYD";
    default:
      return "EGP";
  }
}

/**
 * @function Extreme StyleObject Combiner
 * @argument styles is an array of style arrays, the style array must have the style object at 0 index
  * and the condition at index 1
  * @example esc([[stylesheet1, true], [stylesheet2, isLoggedIn]])
 */
export function esc(styles: any[]) {

  let styleObject = {};
  styles.map((sheet, index) => {
    if (sheet?.[1]) {
      styleObject = { ...styleObject, ...sheet[0] }
    }
  });
  return styleObject
}